import Multiselect from 'vue-multiselect'
export default {
	data: () => ({
		airportprices: {
			id: 0,
			airline_pair: "",
		},
		loading: false,
		error: "",
		backUrl: "/destinationprices",
		cityCodes: [],
		country: [],
		airlines: [],
		selectedAirlines: [],
	}),
	components: {
		Multiselect
	},
	methods: {
		customLabel({ name, code }) {
			return `${name} – ${code}`
		},
		resetForm() {
			this.$refs.observer.reset();
			this.airportprices = {
				id: 0,
			};
		},
		validateAirportPrice() {
			this.airportprices.id > 0 ? this.update() : this.add();
			this.loading = true;
			setTimeout(() => {
				this.loading = false;
			}, 10000);
		},
		update() {
			let _vm = this;
			// Create an empty array to store airline codes
			const airline = [];
			this.selectedAirlines.forEach(element => {
				airline.push(element.code);
			});
			// Convert the array of airline codes to a comma-separated string
			_vm.airportprices.airline_pair = airline.join(",");
			// Send a POST request to update the airport prices
			this.axios
				.post("/airport-prices/" + this.airportprices.id, {
					...this.airportprices,
					_method: "PATCH"
				})
				.then(function (response) {
					// Update the airport prices data with the response data
					_vm.airportprices = response.data.data;

					// Redirect to the specified back URL
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () {
					// Handle any errors here
				});
		},

		add() {
			let _vm = this;
			const airline = [];
			this.selectedAirlines.forEach(element => {
				airline.push(element.code)
			});
			_vm.airportprices.airline_pair = airline.join(",");
			this.axios
				.post("/airport-prices", _vm.airportprices)
				.then(function (response) {
					_vm.$router.push(_vm.backUrl);
				})
				.catch(function () { });
		},

		getQueryString() {
			let queryString = "?search=";
			return queryString;
		},
		getDetail() {
			let _vm = this;
			// Make a GET request to retrieve airport prices
			this.axios
				.get("/airport-prices/" + this.$route.params.id)
				.then(function (response) {
					_vm.airportprices = response.data.data;
					_vm.selectedAirlines = [];
					// Extract airline pair and add selected airlines
					const airline = _vm.airportprices.airline_pair.split(",");
					_vm.airlines.forEach(element => {
						if (airline.indexOf(element.code) > -1) {
							_vm.selectedAirlines.push({ 'name': element.name, 'code': element.code });
						}
					});

				})
				.catch(function () { });
		},
		//Get City On Airport-Prices Module
		getCityCodes() {
			let _vm = this;
			this.axios
				.get("/airport-prices-city-codes", _vm.cityCodes)
				.then(function (response) {
					_vm.cityCodes = response.data.data;
				})
				.catch(function () { });
		},
		//Get Country On Airport-Prices Module
		getcountry() {
			let _vm = this;
			this.axios
				.get("/airport-prices-countries", _vm.country)
				.then(function (response) {
					_vm.country = response.data.data;
				})
				.catch(function () { });

		},
		//Get Airlines On Airport-Prices Module
		getAirlinesName() {
			let _vm = this;
			const queryString = this.$route.params.id ? '?id=' + this.$route.params.id : '';
			this.axios
				.get("/airline-list" + queryString, _vm.airlines)
				.then(function (response) {
					_vm.airlines = response.data.data;
					if (parseInt(_vm.$route.params.id) > 0) {
						_vm.getDetail();
					}
				})
				.catch(function () { });

		},

	},
	mounted() {
		this.getCityCodes()
		this.getcountry()
		this.getAirlinesName()
	}
};
