import { render, staticRenderFns } from "./index.vue?vue&type=template&id=063ecd3c"
import script from "./index.js?vue&type=script&lang=js&external"
export * from "./index.js?vue&type=script&lang=js&external"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports